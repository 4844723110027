import 'ui-core/basic/inputs/js/inputs';

UX.fieldsCtrl.on.markAll = function ($el) {
    UX.fieldsCtrl.$markAll = $el.closest('.markAll');
    if ($el.is('#selectAll')) {
        UX.fieldsCtrl.$markAll.find('[type="checkbox"]').prop('checked', true);
        $('#deselect').prop('checked', false);
    } else if ($el.is('#deselect')) {
        UX.fieldsCtrl.$markAll.find('[type="checkbox"]').prop('checked', false);
        $('#selectAll').prop('checked', false);
    } else if ($el.prop('checked')) {
        UX.fieldsCtrl.$markAll.find('[type="checkbox"]').prop('checked', true);
    } else {
        UX.fieldsCtrl.$markAll.find('[type="checkbox"]').prop('checked', false);
    }
};
