export default class ACPCommerce {
    constructor() {
        this.init();
    }

    init() {
        this.scrollView = $('.scroll-into-view');
        if (!this.scrollView.length) {
            return;
        }
        const parent = this.scrollView.closest('.purchaseArea');
        if (!parent.length) {
            return;
        }
        this.top = parent.offset().top;
        this.stickyTop = $('.header').height() || 0 + $('.actionsbar').height() || 0;
        this.scroll();
    }

    scroll() {
        if (this.top === 0) {
            return;
        }
        $('html, body').animate(
            {
                scrollTop: this.top - this.stickyTop,
            },
            800
        );
    }
}
window.addEventListener('load', () => {
    UX.acpCommerce = new ACPCommerce();
});
