import 'ui-theme/components/tabs/js/tabs';
const {tab} = UX;
const {control} = tab;
tab.control = () => {
    control();
    $('.journal-menu__dropblock').on('click', '.dropblock--tab a', function (e) {
        const href = $(this).attr('href');
        if (href === '#') {
            e.preventDefault();
            return;
        }
        window.location.href = href;
    });
};
UX.tab = tab;
